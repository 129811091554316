import * as Mousetrap from "mousetrap";
import React, { PropsWithChildren, useCallback, useEffect } from "react";
import useGetSet from "react-use/lib/useGetSet";

import { makeKeyMap } from "~src/shared/command/keyMap";
import {
  ICommandContext,
  ICommandHandler,
  ICommandInfo,
  ICommandMapping,
  ICommandMappingName,
  ICommandName,
  ICommandRunner,
  IRunCommand,
} from "~src/shared/command/types";

type ICommandRegistry = Record<string, ICommandHandler>;

export const CommandContext = React.createContext<ICommandContext | null>(null);

export const CommandProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [getRegistery, setRegistery] = useGetSet<ICommandRegistry>({});
  const [getMapping, setMapping] = useGetSet<ICommandMapping>({});

  const runCommand: IRunCommand = useCallback(
    async (runner, cmd, args) => {
      const handler = getRegistery()[cmd];
      if (handler !== undefined) {
        return handler(runner, args);
      }
    },
    [getRegistery],
  );

  const registerCommand: ICommandContext["registerCommand"] = (
    command: ICommandName,
    handler: ICommandHandler,
  ) => {
    setRegistery({
      ...getRegistery(),
      [command]: handler,
    });
  };

  const unregisterCommand: ICommandContext["unregisterCommand"] = (command: ICommandName) => {
    const registery = { ...getRegistery() };
    delete registery[command];
    setRegistery(registery);
  };

  const addMapping: ICommandContext["addMapping"] = (
    name: ICommandMappingName,
    commands: readonly ICommandInfo[],
  ) => {
    setMapping({
      ...getMapping(),
      [name]: commands,
    });
  };

  const removeMapping: ICommandContext["removeMapping"] = (name: ICommandMappingName) => {
    const newMapping = { ...getMapping() };
    delete newMapping[name];
    setMapping(newMapping);
  };

  useEffect(() => {
    const keyMap = makeKeyMap(getMapping());
    Object.entries(keyMap).forEach(([key, commands]) => {
      Mousetrap.bind([key], (e) => {
        e.preventDefault();

        const results = commands.map((command) => {
          return runCommand(ICommandRunner.KEYBOARD, command.command, command.args);
        });

        // Is this a bug?
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return results.find((r) => r !== undefined && r !== null);
      });
    });
    return () => {
      Mousetrap.reset();
    };
    // NOTE(johnrjj) - Use mapping key here from getMapping
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMapping(), runCommand]);

  const ctx: ICommandContext = {
    runCommand,
    registerCommand,
    unregisterCommand,
    getMapping,
    addMapping,
    removeMapping,
  };

  return <CommandContext.Provider value={ctx}>{children}</CommandContext.Provider>;
};
