import "core-js/features/array/flat";
import "core-js/features/array/flat-map";
import "nprogress/nprogress.css";
import "../shared/styles/carousel.css";

import { Global } from "@emotion/react";
import { OverlayProvider } from "@react-aria/overlays";
import { SSRProvider } from "@react-aria/ssr";
import { addBreadcrumb, Severity } from "@sentry/nextjs";
import { NextPage } from "next";
import { AppProps } from "next/app";
// eslint-disable-next-line no-restricted-imports
import Router from "next/router";
import NProgress from "nprogress";
import React from "react";

import { DatePickerStyles } from "~src/designSystem/molecules/DatePicker";
import { Toaster } from "~src/designSystem/molecules/Toaster";
import { ClientSideScripts } from "~src/shared/app/ClientSideScripts";
import { QueryParamProviderComponent } from "~src/shared/app/use-query-params-context";
import { CommandProvider } from "~src/shared/command/contexts/CommandProvider";
import { DarkModeProvider } from "~src/shared/darkMode/useDarkMode";
import { ErrorBoundary } from "~src/shared/errors/components/ErrorBoundary";
import { ErrorFullPage } from "~src/shared/errors/components/ErrorFullPage";
import { ConsoleLog } from "~src/shared/helpers";
import { ModKeyProvider } from "~src/shared/modKey/useModKey";
import { globalColors } from "~src/shared/styles/globalColors";

NProgress.configure({ showSpinner: false });

let nprogressTimer: NodeJS.Timeout | null = null;

Router.events.on("routeChangeStart", (url) => {
  if (
    url?.includes("pactive=1") !== true &&
    // don't show nprogress if going to the same page
    url !== window.location.pathname
  ) {
    nprogressTimer = setTimeout(() => {
      NProgress.start();
    }, 200);
  }
  addBreadcrumb({
    category: "navigate",
    message: `Navigating to ${url}`,
    level: Severity.Info,
  });
  ConsoleLog(`Loading: ${url}`);
});

Router.events.on("routeChangeComplete", (url) => {
  if (nprogressTimer !== null) {
    clearTimeout(nprogressTimer);
    nprogressTimer = null;
  }

  // Segment
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  window.analytics?.page(url);
  if (url?.includes("pactive=1") !== true) {
    NProgress.done();
  }
  addBreadcrumb({
    category: "navigate",
    message: `Navigated to ${url}`,
    level: Severity.Info,
  });
});

Router.events.on("routeChangeError", () => {
  if (nprogressTimer !== null) {
    clearTimeout(nprogressTimer);
    nprogressTimer = null;
  }

  NProgress.done();
});

/** Props that all components in /pages will receive. */
export interface IAppProps extends AppProps {
  pageProps: Record<string, unknown>;
}

const PipeApp: NextPage<AppProps> = ({ Component, pageProps }) => (
  <SSRProvider>
    <ErrorBoundary fallback={<ErrorFullPage />}>
      <OverlayProvider>
        <CommandProvider>
          <DarkModeProvider>
            <ModKeyProvider>
              {/* Global colors */}
              <Global styles={globalColors} />
              <Toaster />
              <QueryParamProviderComponent>
                <Component {...pageProps} />
              </QueryParamProviderComponent>
              <ClientSideScripts />
            </ModKeyProvider>
            <DatePickerStyles />
          </DarkModeProvider>
        </CommandProvider>
      </OverlayProvider>
    </ErrorBoundary>
  </SSRProvider>
);

export default PipeApp;
