// We add `as const` here for better dev tooling support. It shows us the values when
// autocompleting instead of "string".

// in pixels
export const spacing = {
  zero: "0px",
  xxxs: "2px",
  xxs: "4px",
  xs: "8px",
  sm: "12px",
  md: "16px",
  lg: "20px",
  xl: "32px",
  xxl: "64px",
  xxxl: "128px",
} as const;

export const borderRadii = {
  xs: "2px",
  sm: "4px",
  md: "8px",
  lg: "16px",
  circle: "100%",
} as const;

export const fontSizes = {
  xs: "12px",
  sm: "14px",
  md: "20px",
  lg: "32px",
  xl: "48px",
} as const;
