import { styled, t } from "~src/designSystem/theme";
import { contentColorVariants, fontSizeVariants } from "~src/designSystem/theme/variants";
import { castToStitchesStoryComponent } from "~src/storybook/utils";

export const Text = styled("span", contentColorVariants, fontSizeVariants, {
  // Reset
  fontStyle: "normal",
  textTransform: "none",
  textDecoration: "none",
  textIndent: "0px",
  letterSpacing: t.letterSpacings.sm,

  variants: {
    type: {
      light: {
        fontFamily: t.fonts.sans,
        fontWeight: "400",
      },
      regular: {
        fontFamily: t.fonts.sans,
        fontWeight: "500",
      },
      bold: {
        fontFamily: t.fonts.sans,
        fontWeight: "600",
      },
      display: {
        fontFamily: t.fonts.sans,
        fontWeight: "700",
      },
      code: {
        fontFamily: t.fonts.mono,
        fontWeight: "600",
      },
    },
  },
  defaultVariants: {
    type: "regular",
  },
});

export const StorybookText = castToStitchesStoryComponent(Text);

export const StorybookTextArgs = {
  color: {
    description: "The content color. Uses content colors from the semantic palette.",
    control: { type: "select" },
    table: {
      category: "Text Variants",
      defaultValue: { summary: "contentDefault" },
    },
  },
  type: {
    description: "The text type. This affects the weight and font family.",
    control: { type: "select" },
    table: {
      category: "Text Variants",
      defaultValue: { summary: "regular" },
    },
  },
  size: {
    description: "The text size.",
    control: { type: "select" },
    table: {
      category: "Text Variants",
      defaultValue: { summary: "200" },
    },
  },
  children: {
    description: "The text content to display.",
  },
};
