import { IStatusTagProps } from "~src/designSystem/atoms/StatusTag";
import { IUploadedVendorDataSourceModel } from "~src/shared/types/index";

/**
 * List of all 12 months, fully spelled out.
 */
export const monthsList = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

/**
 * List of all 12 months, abbreviated to the first three letters.
 */
export const abbrevMonthsList = monthsList.map((month) => month.slice(0, 3));

export const blockedDomainsList = [
  /* Default domains included */
  "aol.com",
  "att.net",
  "comcast.net",
  "facebook.com",
  "gmail.com",
  "gmx.com",
  "googlemail.com",
  "hotmail.com",
  "hotmail.co.uk",
  "mac.com",
  "me.com",
  "mail.com",
  "msn.com",
  "live.com",
  "sbcglobal.net",
  "verizon.net",
  "yahoo.com",
  "yahoo.co.uk",

  /* Other global domains */
  "email.com",
  "fastmail.fm",
  "games.com" /* AOL */,
  "gmx.net",
  "hush.com",
  "hushmail.com",
  "icloud.com",
  "iname.com",
  "inbox.com",
  "lavabit.com",
  "love.com" /* AOL */,
  "outlook.com",
  "pobox.com",
  "protonmail.ch",
  "protonmail.com",
  "tutanota.de",
  "tutanota.com",
  "tutamail.com",
  "tuta.io",
  "keemail.me",
  "rocketmail.com" /* Yahoo */,
  "safe-mail.net",
  "wow.com" /* AOL */,
  "ygm.com" /* AOL */,
  "ymail.com" /* Yahoo */,
  "zoho.com",
  "yandex.com",

  /* United States ISP domains */
  "bellsouth.net",
  "charter.net",
  "cox.net",
  "earthlink.net",
  "juno.com",

  /* British ISP domains */
  "btinternet.com",
  "virginmedia.com",
  "blueyonder.co.uk",
  "freeserve.co.uk",
  "live.co.uk",
  "ntlworld.com",
  "o2.co.uk",
  "orange.net",
  "sky.com",
  "talktalk.co.uk",
  "tiscali.co.uk",
  "virgin.net",
  "wanadoo.co.uk",
  "bt.com",

  /* Domains used in Asia */
  "sina.com",
  "sina.cn",
  "qq.com",
  "naver.com",
  "hanmail.net",
  "daum.net",
  "nate.com",
  "yahoo.co.jp",
  "yahoo.co.kr",
  "yahoo.co.id",
  "yahoo.co.in",
  "yahoo.com.sg",
  "yahoo.com.ph",
  "163.com",
  "yeah.net",
  "126.com",
  "21cn.com",
  "aliyun.com",
  "foxmail.com",

  /* French ISP domains */
  "hotmail.fr",
  "live.fr",
  "laposte.net",
  "yahoo.fr",
  "wanadoo.fr",
  "orange.fr",
  "gmx.fr",
  "sfr.fr",
  "neuf.fr",
  "free.fr",

  /* German ISP domains */
  "gmx.de",
  "hotmail.de",
  "live.de",
  "online.de",
  "t-online.de" /* T-Mobile */,
  "web.de",
  "yahoo.de",

  /* Italian ISP domains */
  "libero.it",
  "virgilio.it",
  "hotmail.it",
  "aol.it",
  "tiscali.it",
  "alice.it",
  "live.it",
  "yahoo.it",
  "email.it",
  "tin.it",
  "poste.it",
  "teletu.it",

  /* Russian ISP domains */
  "mail.ru",
  "rambler.ru",
  "yandex.ru",
  "ya.ru",
  "list.ru",

  /* Belgian ISP domains */
  "hotmail.be",
  "live.be",
  "skynet.be",
  "voo.be",
  "tvcablenet.be",
  "telenet.be",

  /* Argentinian ISP domains */
  "hotmail.com.ar",
  "live.com.ar",
  "yahoo.com.ar",
  "fibertel.com.ar",
  "speedy.com.ar",
  "arnet.com.ar",

  /* Domains used in Mexico */
  "yahoo.com.mx",
  "live.com.mx",
  "hotmail.es",
  "hotmail.com.mx",
  "prodigy.net.mx",

  /* Domains used in Canada */
  "yahoo.ca",
  "hotmail.ca",
  "bell.net",
  "shaw.ca",
  "sympatico.ca",
  "rogers.com",

  /* Domains used in Brazil */
  "yahoo.com.br",
  "hotmail.com.br",
  "outlook.com.br",
  "uol.com.br",
  "bol.com.br",
  "terra.com.br",
  "ig.com.br",
  "itelefonica.com.br",
  "r7.com",
  "zipmail.com.br",
  "globo.com",
  "globomail.com",
  "oi.com.br",

  // pipe.com is also blocked by us to avoid fishing.
  "pipe.com",
];

// ---------------------------------------------
// Vendor Statuses

type IVendorStatus =
  | "Active"
  | "PendingReview"
  | "PendingRequirements"
  | "Overdue"
  | "Inactive"
  | "OnHold"
  | "ReadyToTrade";

export const VendorStatusMap: {
  [key in IVendorStatus]: IStatusTagProps;
} = {
  Active: {
    label: "Active",
    color: "green",
  },
  PendingReview: {
    label: "Pending Review",
    color: "orange",
  },
  PendingRequirements: {
    label: "Pending Requirements",
    color: "blue",
  },
  Overdue: {
    label: "Overdue",
    color: "red",
  },
  Inactive: {
    label: "Inactive",
    color: "purple",
  },
  OnHold: {
    label: "On Hold",
    color: "yellow",
  },
  ReadyToTrade: {
    label: "On Hold",
    color: "orange",
  },
};

// ---------------------------------------------
// Vendor Payment Statuses

export type IVendorPaymentStatus = "paid" | "overdue" | "upcoming";

export const VendorPaymentStatusMap: {
  [key in IVendorPaymentStatus]: IStatusTagProps;
} = {
  paid: {
    label: "Paid",
    color: "green",
  },
  overdue: {
    label: "Overdue",
    color: "red",
  },
  upcoming: {
    label: "Upcoming",
    color: "orange",
  },
};

// ---------------------------------------------
// Pipe Support
export const PIPE_CONTACT_URL = "https://www.pipe.com/help?contact";
export const PIPE_HELP_URL = "https://www.pipe.com/help";
export const SUPPORT_PHONE_NUMBER = "+1 (855) 936-1512";
export const UK_SUPPORT_PHONE_NUMBER = "+44 20 8068 9728";

export const SUPPORT_EMAIL = "support@pipe.com";
export const SUPPORT_EMAIL_TEMPLATE_HREF = `mailto:${SUPPORT_EMAIL}?subject=Pipe Inquiry`;

// ---------------------------------------------
// Uploaded Vendor Data Source

export const UploadedVendorDataSourceMap: {
  [key in IUploadedVendorDataSourceModel]: string;
} = {
  subscriptions: "Revenue Streams",
};

export const StatesMap = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UM: "United States Minor Outlying Islands",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
