// This file is generated by scripts/generate-theme.js. DO NOT EDIT.

/* eslint-disable @typescript-eslint/dot-notation */

export const colorsPrimitive = {
  Vis: {
    "Grey": {
      "10": "#090a0b",
      "20": "#0d0e11",
      "30": "#121317",
      "40": "#1b1d22",
      "45": "#2d3039",
      "50": "#484d5b",
      "55": "#5a6072",
      "60": "#6c7389",
      "65": "#81889c",
      "70": "#afb3c0",
      "75": "#d1d4db",
      "80": "#e2e4e9",
      "85": "#e2e4e9",
      "90": "#eeeff2",
      "00": "#040506",
    },
    "Magenta Red": {
      "10": "#290a0d",
      "20": "#390e12",
      "30": "#491217",
      "40": "#62181f",
      "45": "#832129",
      "50": "#a32933",
      "55": "#c4313d",
      "60": "#d24b57",
      "65": "#d65c66",
      "70": "#da6c75",
      "75": "#e28d94",
      "80": "#ebadb3",
      "85": "#f3ced1",
      "90": "#f9e7e8",
      "00": "#180608",
    },
    "Red Orange": {
      "10": "#29120a",
      "20": "#39190e",
      "30": "#492012",
      "40": "#622b18",
      "45": "#833921",
      "50": "#a34729",
      "55": "#c45631",
      "60": "#d26d4b",
      "65": "#d67a5c",
      "70": "#da886c",
      "75": "#e2a28d",
      "80": "#ebbdad",
      "85": "#f3d7ce",
      "90": "#f9ebe7",
      "00": "#180b06",
    },
    "Orange": {
      "10": "#291a0a",
      "20": "#39240e",
      "30": "#492e12",
      "40": "#623d18",
      "45": "#835221",
      "50": "#a36629",
      "55": "#c47a31",
      "60": "#d28f4b",
      "65": "#d6995c",
      "70": "#daa36c",
      "75": "#e2b88d",
      "80": "#ebccad",
      "85": "#f3e0ce",
      "90": "#f9f0e7",
      "00": "#180f06",
    },
    "Orange Yellow": {
      "10": "#2e2405",
      "20": "#403207",
      "30": "#534009",
      "40": "#6e560c",
      "45": "#937210",
      "50": "#b88f14",
      "55": "#dcab18",
      "60": "#e9bc35",
      "65": "#ebc247",
      "70": "#edc85a",
      "75": "#f1d47e",
      "80": "#f5e0a3",
      "85": "#f9edc8",
      "90": "#fcf6e3",
      "00": "#1c1503",
    },
    "Yellow Lime": {
      "10": "#21280b",
      "20": "#2e3810",
      "30": "#3b4814",
      "40": "#4e5f1b",
      "45": "#687f24",
      "50": "#839f2d",
      "55": "#9dbf36",
      "60": "#aece50",
      "65": "#b6d260",
      "70": "#bdd770",
      "75": "#cce090",
      "80": "#dae9af",
      "85": "#e9f2cf",
      "90": "#f4f8e7",
      "00": "#141807",
    },
    "Lime Green": {
      "10": "#14250e",
      "20": "#1c3314",
      "30": "#24421a",
      "40": "#305822",
      "45": "#40762e",
      "50": "#509339",
      "55": "#5fb045",
      "60": "#76c05d",
      "65": "#83c66c",
      "70": "#8fcc7b",
      "75": "#a8d798",
      "80": "#c1e2b6",
      "85": "#daeed3",
      "90": "#ecf6e9",
      "00": "#0c1609",
    },
    "Mint Aqua": {
      "10": "#0c2720",
      "20": "#11362d",
      "30": "#16463a",
      "40": "#1d5d4d",
      "45": "#277c67",
      "50": "#319b81",
      "55": "#3bba9a",
      "60": "#54c9ac",
      "65": "#64ceb4",
      "70": "#73d3bb",
      "75": "#92ddca",
      "80": "#b1e7d9",
      "85": "#d0f0e8",
      "90": "#e8f8f4",
      "00": "#071713",
    },
    "Aqua Blue": {
      "10": "#0a2129",
      "20": "#0e2e39",
      "30": "#123c49",
      "40": "#185062",
      "45": "#216a83",
      "50": "#2985a3",
      "55": "#319fc4",
      "60": "#4bb0d2",
      "65": "#5cb8d6",
      "70": "#6cbfda",
      "75": "#8dcde2",
      "80": "#addbeb",
      "85": "#ceeaf3",
      "90": "#e7f4f9",
      "00": "#061418",
    },
    "Blue Violet": {
      "10": "#0a1229",
      "20": "#0e1939",
      "30": "#122049",
      "40": "#182b62",
      "45": "#213983",
      "50": "#2947a3",
      "55": "#3156c4",
      "60": "#4b6dd2",
      "65": "#5c7ad6",
      "70": "#6c88da",
      "75": "#8da2e2",
      "80": "#adbdeb",
      "85": "#ced7f3",
      "90": "#e7ebf9",
      "00": "#060b18",
    },
    "Violet Purple": {
      "10": "#130c27",
      "20": "#1a1136",
      "30": "#221646",
      "40": "#2d1d5d",
      "45": "#3c277c",
      "50": "#4b319b",
      "55": "#5b3bba",
      "60": "#7254c9",
      "65": "#7e64ce",
      "70": "#8b73d3",
      "75": "#a592dd",
      "80": "#bfb1e7",
      "85": "#d8d0f0",
      "90": "#ece8f8",
      "00": "#0b0717",
    },
    "Purple Pink": {
      "10": "#200c27",
      "20": "#2d1136",
      "30": "#3a1646",
      "40": "#4d1d5d",
      "45": "#67277c",
      "50": "#81319b",
      "55": "#9a3bba",
      "60": "#ac54c9",
      "65": "#b464ce",
      "70": "#bb73d3",
      "75": "#ca92dd",
      "80": "#d9b1e7",
      "85": "#e8d0f0",
      "90": "#f4e8f8",
      "00": "#130717",
    },
  },
  Accent: {
    Navy: {
      "10": "#08090d",
      "20": "#0c0d13",
      "30": "#101219",
      "40": "#171b26",
      "45": "#272d3f",
      "50": "#3e4865",
      "55": "#4e5a7e",
      "60": "#5d6c98",
      "65": "#7481aa",
      "70": "#a6afc9",
      "75": "#d9dde8",
      "80": "#eceef3",
      "85": "#f2f4f7",
      "90": "#f9f9fb",
      "00": "#000000",
    },
    Blue: {
      "10": "#0a1229",
      "20": "#0e1939",
      "30": "#122049",
      "40": "#182b62",
      "45": "#213983",
      "50": "#2947a3",
      "55": "#3156c4",
      "60": "#4b6dd2",
      "65": "#5c7ad6",
      "70": "#6c88da",
      "75": "#8da2e2",
      "80": "#adbdeb",
      "85": "#ced7f3",
      "90": "#e7ebf9",
      "00": "#060b18",
    },
    Copper: {
      "10": "#25160e",
      "20": "#331e14",
      "30": "#42271a",
      "40": "#583422",
      "45": "#76462e",
      "50": "#935739",
      "55": "#b06845",
      "60": "#c07e5d",
      "65": "#c68a6c",
      "70": "#cc967b",
      "75": "#d7ad98",
      "80": "#e2c5b6",
      "85": "#eedcd3",
      "90": "#f6ede9",
      "00": "#160d09",
    },
    Indigo: {
      "10": "#111122",
      "20": "#18182f",
      "30": "#1f1f3d",
      "40": "#2a2a51",
      "45": "#37376c",
      "50": "#454587",
      "55": "#5353a2",
      "60": "#6b6bb3",
      "65": "#7878ba",
      "70": "#8686c1",
      "75": "#a1a1ce",
      "80": "#bcbcdc",
      "85": "#d7d7ea",
      "90": "#ebebf5",
      "00": "#0a0a14",
    },
    Lime: {
      "10": "#20260d",
      "20": "#2c3513",
      "30": "#394418",
      "40": "#4c5b20",
      "45": "#65792a",
      "50": "#7e9735",
      "55": "#98b540",
      "60": "#aac559",
      "65": "#b1ca68",
      "70": "#b9cf77",
      "75": "#c9da95",
      "80": "#d8e4b4",
      "85": "#e8efd2",
      "90": "#f3f7e8",
      "00": "#131708",
    },
  },
  Alpha: {
    "Utility": { Black: "#000000ff", White: "#ffffffff", Transparency: "#000000000" },
    "Alpha White": {
      "10": "#ffffff0a",
      "20": "#ffffff0f",
      "30": "#ffffff14",
      "40": "#ffffff1f",
      "45": "#ffffff29",
      "50": "#ffffff3d",
      "55": "#ffffff52",
      "60": "#ffffff66",
      "65": "#ffffff70",
      "70": "#ffffff85",
      "75": "#ffffffad",
      "80": "#ffffffc7",
      "85": "#ffffffe0",
      "90": "#ffffff",
      "00": "#ffffff05",
    },
    "Alpha Black": {
      "10": "#0000000a",
      "20": "#0000000f",
      "30": "#00000014",
      "40": "#0000001f",
      "45": "#00000029",
      "50": "#0000003d",
      "55": "#00000052",
      "60": "#00000066",
      "65": "#00000070",
      "70": "#00000085",
      "75": "#000000ad",
      "80": "#000000c7",
      "85": "#000000e0",
      "90": "#000000",
      "00": "#00000005",
    },
  },
  Base: {
    Grey: {
      "10": "#0a0a0a",
      "20": "#0f0f10",
      "30": "#141415",
      "40": "#1e1f1f",
      "45": "#323334",
      "50": "#505253",
      "55": "#646668",
      "60": "#787a7d",
      "65": "#8d8f91",
      "70": "#b6b8b9",
      "75": "#e0e0e1",
      "80": "#f5f5f5",
      "85": "#fafafa",
      "90": "#ffffff",
      "00": "#000000",
    },
    Blue: {
      "10": "#091a2a",
      "20": "#0d243b",
      "30": "#112e4b",
      "40": "#163d64",
      "45": "#1d5286",
      "50": "#2566a7",
      "55": "#2c7ac9",
      "60": "#478fd7",
      "65": "#5899da",
      "70": "#68a3de",
      "75": "#8ab8e5",
      "80": "#abcced",
      "85": "#cde0f4",
      "90": "#e6f0f9",
      "00": "#060f19",
    },
    Green: {
      "10": "#0e250e",
      "20": "#143314",
      "30": "#1a421a",
      "40": "#225822",
      "45": "#2e762e",
      "50": "#399339",
      "55": "#45b045",
      "60": "#5dc05d",
      "65": "#6cc66c",
      "70": "#7bcc7b",
      "75": "#98d798",
      "80": "#b6e2b6",
      "85": "#d3eed3",
      "90": "#e9f6e9",
      "00": "#091609",
    },
    Yellow: {
      "10": "#2e2405",
      "20": "#403207",
      "30": "#534009",
      "40": "#6e560c",
      "45": "#937210",
      "50": "#b88f14",
      "55": "#dcab18",
      "60": "#e9bc35",
      "65": "#ebc247",
      "70": "#edc85a",
      "75": "#f1d47e",
      "80": "#f5e0a3",
      "85": "#f9edc8",
      "90": "#fcf6e3",
      "00": "#1c1503",
    },
    Orange: {
      "10": "#301a03",
      "20": "#432404",
      "30": "#562e06",
      "40": "#733d07",
      "45": "#99520a",
      "50": "#c0660c",
      "55": "#e67a0f",
      "60": "#f28f2c",
      "65": "#f3993f",
      "70": "#f4a352",
      "75": "#f6b879",
      "80": "#f9cc9f",
      "85": "#fbe0c5",
      "90": "#fdf0e2",
      "00": "#1d0f02",
    },
    Red: {
      "10": "#2c0707",
      "20": "#3d0a0a",
      "30": "#4f0d0d",
      "40": "#691111",
      "45": "#8c1717",
      "50": "#af1d1d",
      "55": "#d32222",
      "60": "#e03e3e",
      "65": "#e25050",
      "70": "#e56161",
      "75": "#eb8484",
      "80": "#f1a7a7",
      "85": "#f6caca",
      "90": "#fbe5e5",
      "00": "#1a0404",
    },
  },
} as const;

export const uiDark = {
  colors: {
    contentEmphasis: colorsPrimitive["Base"]["Grey"]["90"],
    contentDefault: colorsPrimitive["Base"]["Grey"]["75"],
    contentSecondary: colorsPrimitive["Base"]["Grey"]["65"],
    contentTertiary: colorsPrimitive["Base"]["Grey"]["55"],
    contentDisabled: colorsPrimitive["Base"]["Grey"]["40"],
    contentPlaceholder: colorsPrimitive["Base"]["Grey"]["45"],
    contentInverted: colorsPrimitive["Base"]["Grey"]["90"],
    contentBad: colorsPrimitive["Base"]["Red"]["55"],
    contentGood: colorsPrimitive["Base"]["Green"]["55"],
    surfaceBackground: colorsPrimitive["Base"]["Grey"]["00"],
    surfaceDefault: colorsPrimitive["Base"]["Grey"]["10"],
    surfaceElevate: colorsPrimitive["Base"]["Grey"]["20"],
    surfaceRaise: colorsPrimitive["Base"]["Grey"]["30"],
    surfaceInverted: colorsPrimitive["Base"]["Grey"]["90"],
    surfaceScrim: colorsPrimitive["Alpha"]["Alpha Black"]["80"],
    surfaceTransparent: colorsPrimitive["Alpha"]["Utility"]["Transparency"],
    strokeDefault: colorsPrimitive["Base"]["Grey"]["40"],
    strokeRaise: colorsPrimitive["Base"]["Grey"]["50"],
    strokeStrong: colorsPrimitive["Base"]["Grey"]["55"],
    strokeEmphasis: colorsPrimitive["Base"]["Grey"]["70"],
    strokeElevate: colorsPrimitive["Base"]["Grey"]["45"],
    statusInformativeContent: colorsPrimitive["Base"]["Blue"]["55"],
    statusInformativeSurface: colorsPrimitive["Base"]["Blue"]["20"],
    statusPositiveContent: colorsPrimitive["Base"]["Green"]["55"],
    statusPositiveSurface: colorsPrimitive["Base"]["Green"]["20"],
    statusWarningContent: colorsPrimitive["Base"]["Orange"]["55"],
    statusWarningSurface: colorsPrimitive["Base"]["Orange"]["20"],
    statusNegativeContent: colorsPrimitive["Base"]["Red"]["55"],
    statusNegativeSurface: colorsPrimitive["Base"]["Red"]["20"],
    statusNeutralContent: colorsPrimitive["Base"]["Grey"]["65"],
    statusNeutralSurface: colorsPrimitive["Base"]["Grey"]["45"],
    accentSurfaceElevate: colorsPrimitive["Accent"]["Navy"]["40"],
    accentSurfaceRaise: colorsPrimitive["Accent"]["Navy"]["45"],
    accentContentDefault: colorsPrimitive["Accent"]["Navy"]["90"],
    accentContentSecondary: colorsPrimitive["Accent"]["Navy"]["70"],
    accentContentTertiary: colorsPrimitive["Accent"]["Navy"]["60"],
    interactivePrimaryDefault: colorsPrimitive["Base"]["Grey"]["85"],
    interactivePrimaryHover: colorsPrimitive["Base"]["Grey"]["70"],
    interactivePrimaryPressed: colorsPrimitive["Base"]["Grey"]["75"],
    interactivePrimaryContent: colorsPrimitive["Base"]["Grey"]["20"],
    interactiveAccentDefault: colorsPrimitive["Base"]["Blue"]["55"],
    interactiveAccentHover: colorsPrimitive["Base"]["Blue"]["45"],
    interactiveAccentPressed: colorsPrimitive["Base"]["Blue"]["50"],
    interactiveAccentContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveDefaultDefault: colorsPrimitive["Base"]["Grey"]["40"],
    interactiveDefaultHover: colorsPrimitive["Base"]["Grey"]["45"],
    interactiveDefaultPressed: colorsPrimitive["Base"]["Grey"]["30"],
    interactiveDefaultContent: colorsPrimitive["Base"]["Grey"]["85"],
    interactiveNegativeDefault: colorsPrimitive["Base"]["Red"]["55"],
    interactiveNegativeHover: "#931010",
    interactiveNegativePressed: colorsPrimitive["Base"]["Red"]["50"],
    interactiveNegativeContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveStatesActive: colorsPrimitive["Base"]["Grey"]["85"],
    interactiveStatesSelected: colorsPrimitive["Base"]["Grey"]["20"],
    interactiveStatesDisabled: colorsPrimitive["Base"]["Grey"]["55"],
    interactiveOverlayDefault: colorsPrimitive["Alpha"]["Alpha White"]["30"],
    interactiveOverlayHover: colorsPrimitive["Alpha"]["Alpha White"]["30"],
    interactiveOverlayPressed: colorsPrimitive["Alpha"]["Alpha White"]["50"],
  },
  shadows: {
    focusOuterFocus: "0px 0px 0px 4px #187adc, 0px 0px 0px 2px #000000",
    focusInnerFocus: "inset 0px 0px 0px 3px #000000, inset 0px 0px 0px 2px #187adc",
    shadowsFloat:
      "0px 0px 8px 0px #000000cc, 0px 4px 30px 0px #000000cc, 0px 40px 70px 0px #000000cc, 0px 30px 40px 0px #000000cc, 0px 15px 20px 0px #000000cc, 0px 6px 6px 0px #000000cc, 0px 2px 4px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsDefault:
      "0px 0px 2px 0px #000000cc, 0px 1px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsPop:
      "0px 0px 8px 0px #000000cc, 0px 2px 2px 0px #000000cc, 0px 1px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsRaise:
      "0px 1px 4px 0px #000000cc, 0px 2px 4px 0px #000000cc, 0px 2px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsElevate:
      "0px 0px 8px 0px #000000cc, 0px 10px 10px 0px #000000cc, 0px 5px 5px 0px #000000cc, 0px 2px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
  },
} as const;

export const uiLight = {
  colors: {
    contentEmphasis: colorsPrimitive["Base"]["Grey"]["00"],
    contentDefault: colorsPrimitive["Base"]["Grey"]["30"],
    contentSecondary: colorsPrimitive["Base"]["Grey"]["55"],
    contentTertiary: colorsPrimitive["Base"]["Grey"]["65"],
    contentDisabled: colorsPrimitive["Base"]["Grey"]["75"],
    contentPlaceholder: colorsPrimitive["Base"]["Grey"]["70"],
    contentInverted: colorsPrimitive["Base"]["Grey"]["90"],
    contentBad: colorsPrimitive["Base"]["Red"]["55"],
    contentGood: colorsPrimitive["Base"]["Green"]["55"],
    surfaceBackground: colorsPrimitive["Base"]["Grey"]["80"],
    surfaceDefault: colorsPrimitive["Base"]["Grey"]["90"],
    surfaceElevate: colorsPrimitive["Base"]["Grey"]["85"],
    surfaceRaise: colorsPrimitive["Base"]["Grey"]["75"],
    surfaceInverted: colorsPrimitive["Base"]["Grey"]["30"],
    surfaceScrim: colorsPrimitive["Alpha"]["Alpha Black"]["75"],
    surfaceTransparent: colorsPrimitive["Alpha"]["Utility"]["White"],
    strokeDefault: colorsPrimitive["Base"]["Grey"]["75"],
    strokeRaise: colorsPrimitive["Base"]["Grey"]["65"],
    strokeStrong: colorsPrimitive["Base"]["Grey"]["55"],
    strokeEmphasis: colorsPrimitive["Base"]["Grey"]["30"],
    strokeElevate: colorsPrimitive["Base"]["Grey"]["70"],
    statusInformativeContent: colorsPrimitive["Base"]["Blue"]["55"],
    statusInformativeSurface: colorsPrimitive["Base"]["Blue"]["90"],
    statusPositiveContent: colorsPrimitive["Base"]["Green"]["55"],
    statusPositiveSurface: colorsPrimitive["Base"]["Green"]["90"],
    statusWarningContent: colorsPrimitive["Base"]["Orange"]["55"],
    statusWarningSurface: colorsPrimitive["Base"]["Orange"]["90"],
    statusNegativeContent: colorsPrimitive["Base"]["Red"]["55"],
    statusNegativeSurface: colorsPrimitive["Base"]["Red"]["90"],
    statusNeutralContent: colorsPrimitive["Base"]["Grey"]["55"],
    statusNeutralSurface: colorsPrimitive["Base"]["Grey"]["75"],
    accentSurfaceElevate: colorsPrimitive["Accent"]["Navy"]["85"],
    accentSurfaceRaise: colorsPrimitive["Accent"]["Navy"]["75"],
    accentContentDefault: colorsPrimitive["Accent"]["Navy"]["20"],
    accentContentSecondary: colorsPrimitive["Accent"]["Navy"]["50"],
    accentContentTertiary: colorsPrimitive["Accent"]["Navy"]["60"],
    interactivePrimaryDefault: colorsPrimitive["Base"]["Grey"]["10"],
    interactivePrimaryHover: colorsPrimitive["Base"]["Grey"]["45"],
    interactivePrimaryPressed: colorsPrimitive["Base"]["Grey"]["50"],
    interactivePrimaryContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveAccentDefault: "#2c7ac9",
    interactiveAccentHover: "#1d5286",
    interactiveAccentPressed: "#2566a7",
    interactiveAccentContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveDefaultDefault: colorsPrimitive["Base"]["Grey"]["75"],
    interactiveDefaultHover: colorsPrimitive["Base"]["Grey"]["70"],
    interactiveDefaultPressed: colorsPrimitive["Base"]["Grey"]["65"],
    interactiveDefaultContent: colorsPrimitive["Base"]["Grey"]["10"],
    interactiveNegativeDefault: "#d32222",
    interactiveNegativeHover: "#931010",
    interactiveNegativePressed: "#af1d1d",
    interactiveNegativeContent: colorsPrimitive["Base"]["Grey"]["90"],
    interactiveStatesActive: colorsPrimitive["Base"]["Grey"]["30"],
    interactiveStatesSelected: colorsPrimitive["Base"]["Grey"]["75"],
    interactiveStatesDisabled: colorsPrimitive["Base"]["Grey"]["80"],
    interactiveOverlayDefault: colorsPrimitive["Alpha"]["Alpha Black"]["20"],
    interactiveOverlayHover: colorsPrimitive["Alpha"]["Alpha Black"]["20"],
    interactiveOverlayPressed: colorsPrimitive["Alpha"]["Alpha Black"]["30"],
  },
  shadows: {
    focusOuterFocus: "0px 0px 0px 4px #187adc, 0px 0px 0px 2px #ffffff",
    focusInnerFocus: "inset 0px 0px 0px 3px #ffffff, inset 0px 0px 0px 2px #187adc",
    shadowsFloat:
      "0px 0px 8px 0px #000000cc, 0px 4px 30px 0px #000000cc, 0px 40px 70px 0px #000000cc, 0px 30px 40px 0px #000000cc, 0px 15px 20px 0px #000000cc, 0px 6px 6px 0px #000000cc, 0px 2px 4px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsDefault:
      "0px 0px 2px 0px #000000cc, 0px 1px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsPop:
      "0px 0px 8px 0px #000000cc, 0px 2px 2px 0px #000000cc, 0px 1px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsRaise:
      "0px 1px 4px 0px #000000cc, 0px 2px 4px 0px #000000cc, 0px 2px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
    shadowsElevate:
      "0px 0px 8px 0px #000000cc, 0px 10px 10px 0px #000000cc, 0px 5px 5px 0px #000000cc, 0px 2px 2px 0px #000000cc, 0px 1px 1px 0px #000000cc, inset 0px 0px 0px 1px #ffffff0d",
  },
} as const;
