/**
 * We define our Stitches configuration & theme in this file.
 */

import { createStitches, PropertyValue } from "@stitches/react";

// eslint-disable-next-line import/no-restricted-paths
import { uiDark, uiLight } from "./colors";

const { styled, css, theme, createTheme } = createStitches({
  theme: {
    colors: uiDark.colors,
    shadows: uiDark.shadows,
    space: {
      0: "0px",
      0.5: "2px",
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      8: "32px",
      10: "40px",
      16: "64px",
      20: "80px",
    },
    // These are not a part of the Copper design system, but we provide them
    // so Engineering has width tokens that snap to the 8pt grid. This maps fairly
    // closely to Tailwind's size scale. Given that they have seen success and we don't
    // know how we should be restricting width at the time of writing, this is a fairly
    // safe bet IMO.
    //
    // Bless Vim's <C-r>= built in calculator!
    sizes: {
      0: "0px",
      0.5: "2px",
      1: "4px",
      2: "8px",
      3: "12px",
      4: "16px",
      5: "20px",
      6: "24px",
      7: "28px",
      8: "32px",
      9: "36px",
      10: "40px",
      12: "48px",
      14: "56px",
      16: "64px",
      18: "72px",
      20: "80px",
      24: "96px",
      28: "112px",
      32: "128px",
      36: "144px",
      40: "160px",
      44: "176px",
      48: "192px",
      52: "208px",
      56: "224px",
      60: "240px",
      64: "256px",
      72: "288px",
      80: "320px",
      96: "384px",
    },
    borderWidths: {
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
    },
    borderStyles: {
      solid: "solid",
      dashed: "dashed",
      dotted: "dotted",
      double: "double",
      hidden: "hidden",
      none: "none",
    },
    radii: {
      0: "0px",
      2: "2px",
      4: "4px",
      8: "8px",
      16: "16px",
      32: "32px",
      circle: "9999px",
    },
    fonts: {
      sans: "Inter",
      mono: "'GT America Mono', monospace",
    },
    fontSizes: {
      25: "10px",
      50: "11px",
      100: "12px",
      200: "13px",
      300: "14px",
      400: "16px",
      500: "18px",
      600: "20px",
      700: "24px",
      800: "32px",
      900: "40px",
      1000: "60px",
      1100: "76px",
    },
    fontWeights: {},
    lineHeights: {
      25: "12px",
      50: "16px",
      100: "16px",
      200: "20px",
      300: "20px",
      400: "20px",
      500: "24px",
      600: "24px",
      700: "32px",
      800: "32px",
      900: "44px",
      1000: "60px",
      1100: "76px",
    },
    letterSpacings: {
      sm: "-0.02em",
    },
    transitions: {},
    zIndices: {
      10: "10",
      20: "20",
      30: "30",
      40: "40",
      50: "50",
    },
    media: {
      // The screen sizes are:
      //   xs   = 440px
      //   sm   = 576px
      //   md   = 768px
      //   lg   = 992px
      //   xl   = 1200px
      //   xxl  = 1440px
      //   xxxl = 1920px.
      //
      // We are desktop first; each breakpoint sits between the screen sizes.
      "@xs": "(max-width: 508px)",
      "@sm": "(max-width: 672px)",
      "@md": "(max-width: 880px)",
      "@lg": "(max-width: 1092px)",
      "@xl": "(max-width: 1320px)",
      "@xxl": "(max-width: 1680px)",
    },
  },
  utils: {
    paddingY: (value: PropertyValue<"padding">) => ({
      paddingTop: value,
      paddingBottom: value,
    }),
    paddingX: (value: PropertyValue<"padding">) => ({
      paddingLeft: value,
      paddingRight: value,
    }),
    marginY: (value: PropertyValue<"margin">) => ({
      marginTop: value,
      marginBottom: value,
    }),
    marginX: (value: PropertyValue<"margin">) => ({
      marginLeft: value,
      marginRight: value,
    }),
  },
  prefix: "copper",
});

const lightTheme = createTheme({
  colors: uiLight.colors,
  shadows: uiLight.shadows,
});

export { css, lightTheme, styled, theme as t };
