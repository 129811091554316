/**
 * All Colors
 * defines all colors used in our application.
 *
 * TODO: Restrict import to only theme/index.ts
 */
export const colorPalette = {
  // Red
  red800: "#a63131",
  red500: "#dd4141",
  red100: "#e09fa7",
  red50: "#f8d9d9",

  // Orange
  orange800: "#a36220",
  orange500: "#db8818",
  orange50: "#f7e6d5",

  // Yellow
  yellow500: "#f8c100",
  yellow50: "#fff7df",

  // Grass Green
  grassGreen700: "#5e9850",
  grassGreen500: "#27AE60",

  // Green
  green400: "#25A759",

  // Sea Green
  seaGreen700: "#1e8f5c",
  seaGreen500: "#28BF7A",
  seaGreen400: "#3CD991",
  seaGreen50: "#d4f2e4",

  // Cyan
  cyan500: "#4EA9BD",
  cyan400: "#4FFADE",

  // Blue
  blue800: "#4d72ba",
  blue500: "#5e85d2",
  blue300: "#94b0e5",
  blue50: "#F6FBFF",

  // Purple
  purple500: "#735dd0",
  purple300: "#9692ef",

  // Neutrals
  neutral900: "#14151A", // coolGray900
  neutral800: "#27282B", // coolGray800
  neutral700: "#2F2F35", // coolGray700
  neutral600: "#3A3A40", // coolGray600
  neutral500: "#2C3046",
  neutral400: "#404357",
  neutral300: "#515468",
  neutral200: "#A6A9AE", // gray600
  neutral100: "#C4C4C4", // gray500
  neutral50: "#ECEDF5",
};
