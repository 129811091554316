import { useRouter } from "next/router";
import React, { memo, PropsWithChildren, useMemo } from "react";
import { QueryParamProvider as ContextProvider } from "use-query-params";

export const QueryParamProviderComponent: React.FC<PropsWithChildren<unknown>> = (props) => {
  const { children, ...rest } = props;
  const router = useRouter();
  const match = router.asPath.match(/[^?]+/);
  const pathname = match != null ? match[0] : router.asPath;

  const location = useMemo(
    () =>
      process.browser
        ? window.location
        : ({
            search: router.asPath.replace(/[^?]+/u, ""),
          } as Location),
    [router.asPath],
  );

  const history = useMemo(
    () => ({
      push: ({ search }: Location) =>
        router.push(`${router.pathname}${search}`, { search, pathname }, { shallow: true }),

      replace: ({ search }: Location) =>
        router.replace(`${router.pathname}${search}`, { search, pathname }, { shallow: true }),
    }),
    [pathname, router],
  );

  return (
    <ContextProvider {...rest} history={history} location={location}>
      {children}
    </ContextProvider>
  );
};

export const QueryParamProvider = memo(QueryParamProviderComponent);
