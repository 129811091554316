import styled from "@emotion/styled";

import { Box, IBoxProps } from "~src/designSystem/atoms/Box";

export type IGridboxProps = {
  /** sets grid-template-columns css prop */
  gridTemplateColumns?: string;
  /** sets grid-template-rows css prop */
  gridTemplateRows?: string;
  /** number of columns for the grid */
  columns?: number;
  /** number of row for the grid */
  rows?: number;
  /** sets grid-gap css-prop */
  gap?: string;

  /** this sets grid-auto-flow: column
   * NOTE: when enabling this, you must set a row count or set each element's desired column */
  column?: boolean;
} & IBoxProps;

/** Atomic gridbox element
 *
 * use whenever a grid container is applicable.
 */
export const Gridbox = styled(Box)<IGridboxProps>`
  display: grid;

  grid-template-columns: ${(props) =>
    props.columns !== undefined && `repeat(${props.columns}, 1fr)`};
  grid-template-columns: ${(props) => props.gridTemplateColumns};

  grid-template-rows: ${(props) => props.rows !== undefined && `repeat(${props.rows}, 1fr)`};
  grid-template-rows: ${(props) => props.gridTemplateRows};
  /* grid-template-rows: repeat(auto-fill, 1fr); */

  grid-gap: ${(props) => props.gap ?? "24px"};
  grid-auto-flow: ${(props) => (props.column === true ? "column" : "row")};
`;
