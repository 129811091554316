import React, { PropsWithChildren, useEffect, useState } from "react";
import { Key } from "ts-key-enum";

let defaultIsModPressed = false;
if (typeof window !== "undefined") {
  window.onmousemove = (ev: MouseEvent) => {
    if (ev.ctrlKey || ev.metaKey) {
      defaultIsModPressed = true;
    }
  };
}

const isMod = (key: string): boolean => key === Key.Meta || key === Key.Control;

const ModKeyContext = React.createContext<boolean | null>(null);

export const ModKeyProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [isModPressed, setIsModPressed] = useState<boolean>(defaultIsModPressed);

  // We need to add global listeners for keyboard events so that we can properly track
  // when mod key is pressed or not. This is needed to make sure that even if the search
  // box is in focus (which it is by default when we open the modal), we end up closing
  // the modal if we kept on pressing cmd and hit K again.
  useEffect(() => {
    const onModUp = (e: KeyboardEvent) => {
      if (isMod(e.key)) {
        setIsModPressed(false);
      }
    };
    const onModDown = (e: KeyboardEvent) => {
      if (isMod(e.key)) {
        setIsModPressed(true);
      }
    };

    document.addEventListener("keydown", onModDown);
    document.addEventListener("keyup", onModUp);

    return () => {
      document.removeEventListener("keydown", onModDown);
      document.removeEventListener("keyup", onModUp);
    };
  }, [setIsModPressed]);

  return <ModKeyContext.Provider value={isModPressed}>{children}</ModKeyContext.Provider>;
};

export const useModKey = (): boolean => {
  const isModPressed = React.useContext(ModKeyContext);
  if (isModPressed === null) {
    throw new Error("Not in a ModKey context.");
  }
  return isModPressed;
};
