import { ThemeProvider } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

import { Text } from "~src/designSystem/atoms/Text";
import { Flexbox } from "~src/designSystem/layout/Flexbox";
import { Gridbox } from "~src/designSystem/layout/Gridbox";
import { Spacer } from "~src/designSystem/layout/Spacer";
import { Head } from "~src/designSystem/sortLater/Head";
import { TABLET_MAX } from "~src/shared/styles/responsive";
import { themeDark } from "~src/shared/theme/darkVariant";

export const ErrorFullPage: React.FC = () => (
  <ThemeProvider theme={themeDark}>
    <WrapperContainer gridTemplateColumns="auto auto">
      <Head title="Something Went Wrong | Pipe" />
      <Flexbox justifyContent="center" alignItems="center" pad width="600px">
        <Flexbox justifyContent="center" maxWidth="480px" pad>
          <Text type="display" color="contentEmphasis" size="900">
            Uh oh...
          </Text>
          <Spacer y="16px" />
          <Text type="light" color="contentSecondary" size="400">
            Something went wrong. Our engineers have been notified.
          </Text>
        </Flexbox>
      </Flexbox>
    </WrapperContainer>
  </ThemeProvider>
);

const WrapperContainer = styled(Gridbox)`
  height: 100vh;
  background: #000000;
  @media (max-width: ${TABLET_MAX}) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
`;
