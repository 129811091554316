import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import { css, Global, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useState } from "react";
import { SingleDatePicker } from "react-dates";

export type IDatePickerProps = {
  id: string;
  date: moment.Moment | null;
  onDateChange: (arg: moment.Moment | null) => void;
  anchorDirection?: "left" | "right";
  openDirection?: "down" | "up";
  /* Currently unused. */
  error?: string;
};

/**
 * See https://github.com/airbnb/react-dates#singledatepicker for documentation.
 */
export const DatePicker: React.FC<IDatePickerProps> = (props) => {
  const [focused, setFocused] = useState<boolean>(false);

  return (
    <Styles>
      <SingleDatePicker
        numberOfMonths={1}
        transitionDuration={160}
        small
        noBorder
        appendToBody
        isOutsideRange={() => false}
        focused={focused}
        onFocusChange={({ focused: f }) => setFocused(f)}
        verticalSpacing={16}
        displayFormat="YYYY-MM-DD"
        anchorDirection={props.anchorDirection ?? "left"}
        openDirection={props.openDirection ?? "down"}
        id={props.id}
        date={props.date}
        onDateChange={props.onDateChange}
      />
    </Styles>
  );
};

const Styles = styled.div`
  * {
    font-family: ${(props) => props.theme.textStyles.Regular.Body200.fontFamily} !important;
    font-weight: ${(props) => props.theme.textStyles.Regular.Body200.fontWeight} !important;
    font-size: ${(props) => props.theme.textStyles.Regular.Body200.fontSize} !important;
    text-transform: ${(props) => props.theme.textStyles.Regular.Body200.textTransform} !important;
    text-decoration: ${(props) => props.theme.textStyles.Regular.Body200.textDecoration} !important;
    text-indent: ${(props) => props.theme.textStyles.Regular.Body200.textIndent} !important;
    line-height: ${(props) => props.theme.textStyles.Regular.Body200.lineHeight} !important;
    letter-spacing: ${(props) => props.theme.textStyles.Regular.Body200.letterSpacing} !important;
  }

  width: 100%;
  height: 100%;

  .SingleDatePicker,
  .SingleDatePickerInput,
  .DateInput,
  .DateInput_input {
    width: 100%;
  }

  .SingleDatePickerInput,
  .DateInput {
    background: none;
    background-color: none;
  }

  .DateInput_input {
    background-color: ${(props) => props.theme.components.TextField.background.default};
    color: ${(props) => props.theme.components.TextField.color.default};
    padding: 8px 12px !important;
    border-radius: 6px;
    border: 1px solid ${(props) => props.theme.components.TextField.border.default} !important;
  }

  .DateInput_fang {
    display: none;
  }
`;

export const DatePickerStyles: React.FC = () => {
  const theme = useTheme();

  return (
    <Global
      styles={css`
        .SingleDatePicker_picker {
          z-index: 200;

          border-radius: ${theme.borderRadii.md};
          border: 1px solid ${theme.components.DatePicker.border} !important;
          overflow: hidden;

          * {
            font-family: ${theme.textStyles.Regular.Body200.fontFamily} !important;
            font-weight: ${theme.textStyles.Regular.Body200.fontWeight} !important;
            font-size: ${theme.textStyles.Regular.Body200.fontSize} !important;
            text-transform: ${theme.textStyles.Regular.Body200.textTransform} !important;
            text-decoration: ${theme.textStyles.Regular.Body200.textDecoration} !important;
            text-indent: ${theme.textStyles.Regular.Body200.textIndent} !important;
            line-height: ${theme.textStyles.Regular.Body200.lineHeight} !important;
            letter-spacing: ${theme.textStyles.Regular.Body200.letterSpacing} !important;
          }

          .DayPickerKeyboardShortcuts_buttonReset {
            display: none;
          }

          * {
            background: ${theme.components.DatePicker.background};
            background-color: ${theme.components.DatePicker.background};
            color: ${theme.components.DatePicker.text};
            border: none !important;
          }

          .DayPicker_weekHeader * {
            color: ${theme.components.DatePicker.weekHeader} !important;
          }

          .CalendarMonth_caption * {
            color: ${theme.components.DatePicker.month} !important;
          }

          .CalendarMonth_table {
            margin-top: 8px;
          }

          .CalendarDay {
            vertical-align: middle;
          }

          .DayPickerNavigation_button:hover,
          .DayPickerNavigation_button:hover svg,
          .CalendarDay:hover {
            transition: ${theme.transition};
            background: ${theme.components.DatePicker.backgroundHover};
            background-color: ${theme.components.DatePicker.backgroundHover};
            border-radius: 2px;
          }
        }
      `}
    />
  );
};
