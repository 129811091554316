// eslint-disable-next-line no-restricted-imports
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/nextjs";
import { ComponentProps, ReactNode } from "react";

type ISentryErrorBoundaryProps = ComponentProps<typeof SentryErrorBoundary>;

type IProps = {
  children: ReactNode;

  /**
   * A fallback component that gets rendered when the error boundary encounters an error.
   *
   * Can either provide a React Component, or a function that returns React Component as
   * a valid fallback prop. If a function is provided, the function will be called with
   * the error, the component stack, and an function that resets the error boundary on error.
   *
   */
  fallback: ISentryErrorBoundaryProps["fallback"];
};

export const ErrorBoundary: React.FC<IProps> = (props) => {
  return <SentryErrorBoundary fallback={props.fallback}>{props.children}</SentryErrorBoundary>;
};
