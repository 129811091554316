import { captureException, Event, withScope } from "@sentry/nextjs";
// eslint-disable-next-line no-restricted-imports
import Router from "next/router";
import toast from "react-hot-toast";

import { isWindowDefined } from ".";
import { stringToBool } from "./booleanCoercion";

/**
 * Sentry logger for custom errors.
 * @param event Sentry event object to send to Sentry. Recommended to use `message` and `extra` properties. `message` will show up as error title.
 *
 * @param messageToUser Optional message to provide to user. Defaults to "An unexpected error occured. Our engineers have been notified."
 */
export const logErrorEventAndToast = (
  context: {
    /**
     * Message to show in Sentry as the title of the event (prefer low cardinality)
     * This is never displayed to the user.
     */
    message?: string;
    /**
     * Message displayed to the user
     */
    messageToUser?: string;
  } & Pick<Event, "tags" | "extra"> = {},
): void => {
  let msg = "empty message";
  if (context.message !== undefined) {
    msg = context.message;
  }

  // Presentation (eugh).
  if (context.messageToUser !== undefined) {
    toast.error(context.messageToUser);
  } else if (context.message !== undefined) {
    toast.error(context.message);
  }

  return logError(new Error(msg), context);
};

/**
 * Logs an Error to Sentry.
 * Use this function if you want to log a stack trace.
 * @param error
 */
export const logError = (
  error: Error,
  context: {
    /**
     * Message to show in Sentry as the title of the event (prefer low cardinality)
     * This is never displayed to the user.
     */
    message?: string;
  } & Pick<Event, "tags" | "extra"> = {},
): void => {
  const { tags, extra, message } = context;
  withScope((scope) => {
    if (tags !== undefined) {
      scope.setTags(tags);
    }
    if (extra !== undefined) {
      scope.setExtras(extra);
    }
    if (stringToBool(context.message)) {
      scope.setExtra("originalMessage", error.message);
      error.message = context.message;
    }

    if (message !== undefined) {
      console.error(message, error);
    }
    if (isWindowDefined) {
      // add more stuff to the event
      scope.setExtra("routerPath", Router.asPath);
    }

    captureException(error);
  });
};
