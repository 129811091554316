import NextHead from "next/head";
import React, { ReactNode } from "react";

interface IProps {
  title?: string;
  desc?: string;
  canonical?: string;
  image?: string;
  jsScript?: string;
  twitterSummary?: string;
  children?: ReactNode;
}

export const Head: React.FC<IProps> = (props: IProps) => {
  // For tab title
  const title = props.title ?? "Pipe";
  // For social previews
  const siteName = "Pipe – Instant access to your annual cash flow";
  const desc =
    props.desc ??
    "Get up to the full annual value of your booked monthly and quarterly subscriptions. Scale your business without dilution or debt.";
  // Set canonical so that all Pipe urls are treated the same by Facebook, Twitter, etc.
  // Match with canonical from Webflow
  const canonical = props.canonical ?? "https://pipe.com/";
  const image =
    props.image ??
    "https://uploads-ssl.webflow.com/5f36e1e9e7d0bd0aa019885d/5f457ce7c06ce726cfe58dba_Metatag.png";
  const twitterUsername = "@pipe";
  const twitterSummary = props.twitterSummary ?? desc;
  // For additional scripts
  const { jsScript } = props;

  return (
    <NextHead>
      <title>{title}</title>
      <meta name="description" content={desc} />
      <meta property="og:type" content="website" />
      <meta name="og:title" property="og:title" content={siteName} />
      <meta name="og:description" property="og:description" content={desc} />
      <meta property="og:site_name" content={siteName} />
      <meta property="og:url" content={`${canonical}`} />
      <meta property="og:image" content={`${image}`} />
      <meta name="twitter:image" content={`${image}`} />
      <meta name="twitter:card" content={twitterSummary} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:site" content={twitterUsername} />
      <meta name="twitter:creator" content={twitterUsername} />
      <link rel="icon" type="image/png" href="/static/assets/brand/favicon.ico" />
      <link rel="apple-touch-icon" href="/static/assets/brand/favicon.ico" />
      {canonical && <link rel="canonical" href={`${canonical}`} />}
      {jsScript !== undefined && <script type="text/javascript" src={`${jsScript}`} />}
      {props.children}
    </NextHead>
  );
};
