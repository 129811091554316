import * as Stitches from "@stitches/react";

// Adapted from https://gist.github.com/matthewsimo/dbcaa136ea9972dba5e5e6c8ae5f2c20.
interface StitchesMedia {
  [x: string]: unknown;
  initial?: unknown;
  as?: unknown;
  css?: Stitches.CSS;
}

// We exclude these type properties from the `ComponentVariants` type so that storybook can more
// easily understand the type arguments.
//
// Assumption: There are no numerical variants. Strings are more ergonomic for variants.
type StitchesPropsToExclude = "true" | "false" | number | StitchesMedia;

/**
 * This takes a Stitches component and casts it to a set of types that better represent
 * the available variants. Without this, the responsive object props clutter the
 * Storybook documentation.
 */
export const castToStitchesStoryComponent = <C extends React.JSXElementConstructor<unknown>>(
  Component: C,
) => {
  type ComponentProps = Omit<React.ComponentProps<C>, "css" | "ref"> & {
    /**
     * CSS overrides per the Stitches framework.
     */
    css?: Stitches.CSS;
    ref?: React.RefObject<unknown>;
    /**
     * What HTML tag to render as. This enables polymorphism.
     */
    as?:
      | "a"
      | "div"
      | "span"
      | "p"
      | "h1"
      | "h2"
      | "h3"
      | "h4"
      | "h5"
      | "h6"
      | "i"
      | "u"
      | "em"
      | "s"
      | "samp"
      | "sub"
      | "sup";
  };
  type VariantProps = Stitches.VariantProps<C>;
  type StoryVariants = {
    [P in keyof VariantProps]: Exclude<VariantProps[P], StitchesPropsToExclude>;
  };
  type StoryProps = Omit<ComponentProps, keyof VariantProps> & StoryVariants;

  return Component as unknown as (props: StoryProps) => JSX.Element;
};
