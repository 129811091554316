export const textStyles = {
  Regular: {
    Body700: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "24px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
    },
    Body600: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "20px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "24px",
      letterSpacing: "-0.02em",
    },
    Body500: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "18px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "24px",
      letterSpacing: "-0.02em",
    },
    Body400: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Body300: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "14px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Body200: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "13px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Body100: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "12px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    Body50: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "11px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    Body25: {
      fontFamily: "Inter",
      fontWeight: 500,
      fontSize: "10px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "12px",
      letterSpacing: "-0.02em",
    },
  },
  Bold: {
    Body900: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "40px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
    },
    Body800: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "32px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
    },
    Body700: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "24px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "32px",
      letterSpacing: "-0.02em",
    },
    Body600: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "20px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "24px",
      letterSpacing: "-0.02em",
    },
    Body500: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "18px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "24px",
      letterSpacing: "-0.02em",
    },
    Body400: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "16px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Body300: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "14px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Body200: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "13px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Body100: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "12px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    Body50: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "11px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "16px",
      letterSpacing: "-0.02em",
    },
    Body25: {
      fontFamily: "Inter",
      fontWeight: 600,
      fontSize: "10px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "12px",
      letterSpacing: "-0.02em",
    },
  },
  Display: {
    Display1: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "64px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "76px",
      letterSpacing: "-0.03em",
    },
    Display2: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "52px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "60px",
      letterSpacing: "-0.03em",
    },
    Display3: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "40px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
    },
    Display4: {
      fontFamily: "Inter",
      fontWeight: 700,
      fontSize: "32px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "44px",
      letterSpacing: "-0.02em",
    },
  },
  Code: {
    Code400: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Code300: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "14px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Code200: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "13px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Code100: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "12px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
  },
  Trading: {
    Trading400: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "16px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Trading300: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "14px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Trading200: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "13px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
    Trading100: {
      fontFamily: "'GT America Mono', monospace",
      fontWeight: 500,
      fontSize: "12px",
      textTransform: "none",
      textDecoration: "none",
      textIndent: "0px",
      lineHeight: "20px",
      letterSpacing: "-0.02em",
    },
  },
} as const;
