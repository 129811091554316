// The point of this file is to explicitly handle @typescript-eslint/strict-boolean-expressions.

/**
 * Gets the boolean value associated with the string.
 * @param stringLike String/null/undefined
 */
export const stringToBool = (stringLike: string | undefined | null): stringLike is string =>
  stringLike !== null && stringLike !== undefined && stringLike !== "";

export const stringIsEmpty = (stringLike: string | undefined | null): boolean =>
  stringLike === null || stringLike === undefined || stringLike.trim() === "";

/**
 * Checks that a value is not null and refines the type. This should be used
 * over `x !== null when the type inference is needed, e.g. in a `.filter()`
 * call.
 */
export const isNotNull = <T>(value: T | null): value is T => value !== null;

/**
 * Checks that a value is not undefined and refines the type. This should be
 * used over `x !== undefined when the type inference is needed, e.g. in a
 * `.filter()` call.
 */
export const isNotUndefined = <T>(value?: T): value is T => typeof value !== "undefined";
