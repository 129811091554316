import { ThemeProvider } from "@emotion/react";
import { noop } from "lodash";
import React, { PropsWithChildren } from "react";

import { lightTheme } from "~src/designSystem/theme";
import useLocalStorageState from "~src/shared/helpers/useLocalStorageState";

import { themeDark } from "../theme/darkVariant";
import { themeLight } from "../theme/lightVariant";

type ISetDarkMode = (darkMode: boolean) => void;

const DEFAULT_DARK_MODE = true;

interface ISetDarkModeContext {
  darkMode: boolean;
  setDarkMode: ISetDarkMode;
}

const SetDarkModeContext = React.createContext<ISetDarkModeContext>({
  darkMode: DEFAULT_DARK_MODE,
  setDarkMode: noop,
});

export const DarkModeProvider: React.FC<PropsWithChildren<unknown>> = ({ children }) => {
  const [darkMode, setDarkMode] = useLocalStorageState<boolean>("dark-mode", DEFAULT_DARK_MODE);
  const theTheme = darkMode ? themeDark : themeLight;
  return (
    <SetDarkModeContext.Provider value={{ darkMode, setDarkMode }}>
      {/* This is Stitches' theming. */}
      <div className={darkMode ? "" : lightTheme}>
        <ThemeProvider theme={theTheme}>{children}</ThemeProvider>
      </div>
    </SetDarkModeContext.Provider>
  );
};

export const useSetDarkMode = (): ISetDarkModeContext => {
  return React.useContext(SetDarkModeContext);
};
