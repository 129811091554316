import { css, SerializedStyles, Theme } from "@emotion/react";

// TODO(ASAP): Remove these.

const base = (theme: Theme): SerializedStyles => css`
  background: ${theme.components.Box.background};
  border-radius: 4px;
  box-shadow: ${theme.components.Box.boxshadow};
  border-color: ${theme.components.Input.border};
`;
// background-color: ${props.theme.old.functional.background.default};

const layout = (): SerializedStyles => css`
  background: #1f2023;
  color: #fff;
`;

const accent = (theme: Theme): SerializedStyles => css`
  color: ${theme.components.Text.title};
  background: ${theme.components.Box.accent.background};
`;

export type IContainerVariants = keyof typeof containerVariants;

export const containerVariants = {
  base,
  layout,
  accent,
};
