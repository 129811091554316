/*
 * This module contains a modified version of Palantir's blueprintjs
 * Icon component, sourced from @blueprintjs/core.
 */

/*
 * Copyright 2017 Palantir Technologies, Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { IconName, IconSvgPaths16, IconSvgPaths20 } from "@blueprintjs/icons";
import React from "react";

export interface IIconProps {
  /**
   * Color of icon. This is used as the `fill` attribute on the `<svg>` image
   * so it will override any CSS `color` property, including that set by
   * `intent`. If this prop is omitted, icon color is inherited from
   * surrounding text.
   */
  color?: string;

  /**
   * String for the `title` attribute on the rendered element, which will appear
   * on hover as a native browser tooltip.
   */
  htmlTitle?: string;

  className?: string;

  /**
   * Name of a Blueprint UI icon, or an icon element, to render. This prop is
   * required because it determines the content of the component, but it can
   * be explicitly set to falsy values to render nothing.
   *
   * - If `null` or `undefined` or `false`, this component will render nothing.
   * - If given an `IconName` (a string literal union of all icon names), that
   *   icon will be rendered as an `<svg>` with `<path>` tags. Unknown strings
   *   will render a blank icon to occupy space.
   * - If given a `JSX.Element`, that element will be rendered and _all other
   *   props on this component are ignored._ This type is supported to
   *   simplify icon support in other Blueprint components. As a consumer, you
   *   should avoid using `<Icon icon={<Element />}` directly; simply render
   *   `<Element />` instead.
   */
  icon: IconName;

  /**
   * Size of the icon, in pixels. Blueprint contains 16px and 20px SVG icon
   * images, and chooses the appropriate resolution based on this prop.
   *
   * @default SIZE_STANDARD = 16
   */
  size?: number;

  /**
   * HTML tag to use for the rendered element.
   *
   * @default "span"
   */
  tagName?: keyof JSX.IntrinsicElements;

  /**
   * Description string. This string does not appear in normal browsers, but
   * it increases accessibility. For instance, screen readers will use it for
   * aural feedback. By default, this is set to the icon's name. Pass an
   * explicit falsy value to disable.
   */
  title?: string | false | null;
}

const SIZE_STANDARD = 16;
const SIZE_LARGE = 20;

/** Blueprint.js Icons */
export const BlueprintIcon: React.FC<IIconProps> = (props) => {
  const {
    className,
    color,
    htmlTitle,
    size: iconSize = SIZE_STANDARD,
    title,
    tagName = "span",
    ...htmlProps
  } = props;

  // choose which pixel grid is most appropriate for given icon size
  const pixelGridSize = iconSize >= SIZE_LARGE ? SIZE_LARGE : SIZE_STANDARD;
  // render path elements, or nothing if icon name is unknown.
  const paths = renderSvgPaths(pixelGridSize, props.icon);

  const viewBox = `0 0 ${pixelGridSize} ${pixelGridSize}`;

  return React.createElement(
    tagName,
    {
      ...htmlProps,
      className,
      title: htmlTitle,
    },
    <svg
      fill={color ?? "currentColor"}
      data-icon={props.icon}
      width={iconSize}
      height={iconSize}
      viewBox={viewBox}
    >
      {title !== undefined && <desc>{title}</desc>}
      {paths}
    </svg>,
  );
};

/** Render `<path>` elements for the given icon name. Returns `null` if name is unknown. */
function renderSvgPaths(pathsSize: IIconProps["size"], iconName: IconName): JSX.Element[] | null {
  const svgPathsRecord = pathsSize === 16 ? IconSvgPaths16 : IconSvgPaths20;
  const pathStrings = svgPathsRecord[iconName];
  return pathStrings.map((d, i) => <path key={i} d={d} fillRule="evenodd" />);
}
