import { IListFilter, IListInfo } from "~/src/shared/lists/types";
import { ICommandKMode, ICommandKModeArgs } from "~src/shared/commandK/types";
import { IListModel } from "~src/shared/lists/types/models";
import { IDestination } from "~src/vendor/layout/goto";

export interface ICommand<C extends ICommandName = ICommandName> {
  command: C;
  args?: ICommands[C];
}

export enum ICommandRunner {
  KEYBOARD = 1,
  COMMAND_K,
  BUTTON,
}

export type ICommandHandler = (
  runner: ICommandRunner,
  args?: unknown,
) => boolean | void | Promise<boolean | void>;

export type IRunCommand = <C extends ICommandName>(
  runner: ICommandRunner,
  command: C,
  args?: unknown,
) => boolean | void | Promise<boolean | void>;

export interface ICommandContext {
  runCommand: IRunCommand;

  // Command registry helpers.
  registerCommand: (command: ICommandName, handler: ICommandHandler) => void;
  unregisterCommand: (command: ICommandName) => void;

  // Command group mappings.
  getMapping: () => ICommandMapping;
  addMapping: (name: ICommandMappingName, commands: readonly ICommandInfo[]) => void;
  removeMapping: (name: ICommandMappingName) => void;
}

/**
 * Types of all commands.
 */
export type ICommands = {
  "command-k/exit": null;
  "command-k/back": null;
  "command-k/toggle": null;
  "command-k/show": {
    mode: ICommandKMode;
    args: ICommandKModeArgs<ICommandKMode>;
  };
  "command-k/down": null;
  "command-k/select": null;
  "command-k/up": null;

  "list-builder/edit": null;
  "list-builder/edit-columns": null;
  "list-builder/edit-filters": null;
  "list-builder/duplicate": null;
  "list-builder/cancel": null;
  "list-builder/save": null;
  "list-builder/toggle-side-panel": null;
  "list-builder/replace-filter": {
    filter: IListFilter<IListModel>;
    existingFilter?: IListFilter<IListModel>;
  };
  "list-builder/add-column": {
    column: string;
  };
  "list-builder/remove-column": {
    column: string;
  };
  "list-builder/item-scroll": "up" | "down";

  "lists/new": null;
  "lists/delete": null;
  "lists/search": null;
  "lists/open": {
    list: IListInfo<IListModel>;
  };

  "goto": IDestination;

  // These are only used in Admin.
  "exit": null;
  "beast-mode-toggle": null;
  "side-panel-toggle": null;
  "side-panel-resize": null;
  "list-create": null;
};

export type ICommandName = keyof ICommands;

export interface ICommandInfo {
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  title: string;
  command: ICommand;
  bindings: readonly string[];
  hideInCommandKModal?: boolean;
}

export enum ICommandMappingName {
  CommandK = "Command K",
  Goto = "Goto",
  List = "List",
  ListEditing = "List Editing",
  Lists = "Lists",
}

export type ICommandMapping = {
  [K in ICommandMappingName]?: readonly ICommandInfo[];
};
