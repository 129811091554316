import React from "react";

const AUTOPILOT_SCRIPT = `(function(o){var b="https://apeagle.io/anywhere/",t="1429334315bd4381b0066f21fe8d1b48e8fc7713d6744e8b9cccb27ca4a4808c",a=window.AutopilotAnywhere={_runQueue:[],run:function(){this._runQueue.push(arguments);}},c=encodeURIComponent,s="SCRIPT",d=document,l=d.getElementsByTagName(s)[0],p="t="+c(d.title||"")+"&u="+c(d.location.href||"")+"&r="+c(d.referrer||""),j="text/javascript",z,y;if(!window.Autopilot) window.Autopilot=a;if(o.app) p="devmode=true&"+p;z=function(src,asy){var e=d.createElement(s);e.src=src;e.type=j;e.async=asy;if(l.parentNode){l.parentNode.insertBefore(e,l)};};y=function(){z(b+t+'?'+p,true);};if(window.attachEvent){window.attachEvent("onload",y);}else{window.addEventListener("load",y,false);}})({"app":true});`;

export const ClientSideScripts: React.FC = () => {
  return (
    <>
      {/* Autopilot */}
      <script
        type="text/javascript"
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: AUTOPILOT_SCRIPT,
        }}
      />
    </>
  );
};
