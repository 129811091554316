import { addBreadcrumb, Severity } from "@sentry/nextjs";
import { round } from "lodash";

import { blockedDomainsList } from "~src/shared/constants";

export * from "./loggers";

export const isWindowDefined = typeof window !== "undefined";

/** Console.logs only in non-production browser environments.  */
export const ConsoleLog = (...data: unknown[]): void => {
  if (process.env.NODE_ENV === "production" || !isWindowDefined) {
    return;
  }

  addBreadcrumb({
    category: "console-log",
    data,
    message: typeof data[0] === "string" ? data[0] : JSON.stringify(data),
    level: Severity.Debug,
  });

  // eslint-disable-next-line no-console
  console.log(...data);
};

/** Returns the domain of a given email. */
export const getDomainFromEmail = (email: string): string => {
  return email.substring(email.lastIndexOf("@") + 1);
};

/** Returns true if email's domain is listed in blockedDomainsList. Otherwise returns false. */
export const isEmailDomainBlocked = (email: string): boolean => {
  return blockedDomainsList.includes(getDomainFromEmail(email));
};

/**
 * Handles the logic for plural and singular forms of terms based on the given numeric value.
 * Returns singular form when value === 1.
 * Otherwise (including zero and negative values), returns plural.
 * @param value The numeric value of the term you want to pluralize.
 * @param singular  The singular form of the term.
 * @param plural  The plural form of the term.
 */
export const pluralizer = (value: number | undefined, singular: string, plural: string): string => {
  if (value === undefined) return "N/A";
  const valueStr = value % 1 !== 0 ? value.toFixed(2) : value.toLocaleString();
  return `${valueStr} ${pluralizerSuffix(value, singular, plural)}`;
};

/**
 * Handles the logic for plural and singular forms of terms based on the given numeric value.
 * Returns singular form when value === 1.
 * Otherwise (including zero and negative values), returns plural.
 * @param value The numeric value of the term you want to pluralize.
 * @param singular  The singular form of the term.
 * @param plural  The plural form of the term.
 */
export const pluralizerSuffix = (
  value: number | undefined,
  singular: string,
  plural: string,
): string => {
  if (value === undefined) return "N/A";
  if (value !== 1) return plural;
  return singular;
};

/**
 * Rounds a percentage to the nearest 4 decimal places
 * @param num
 */
export const roundPercent = (num: number, sigfig = 4): number =>
  Math.round(10 ** sigfig * num * 100) / (10 ** sigfig * 100);

/**
 * Checks if email is a real Pipe admin email. Do not use this to check if a user is a
 * Pipe admin. Use user type instead.
 * Returns false for 'x+y@pipe.com' used in demo+test accounts.
 * */
export const isPipeAdminEmail = (email: string | undefined): boolean => {
  if (email === undefined) {
    return false;
  }
  return email.match(/@pipe.com/i) !== null && email.match(/(.*?)\++(.*?)@pipe.com/) === null;
};

/**
 * Capitalizes only the first letter of the string and leaves the rest of the string as-is.
 * In contrast, `capitalize` from lodash converts the rest of the string to lower case.
 *
 * Ex: "this is a Message" => "This is a Message"
 */
export const capitalizeFirstLetter = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

/** Provides the number of months between two JS dates. */
export const monthDiff = (dateFrom: Date, dateTo: Date): number => {
  return (
    dateTo.getMonth() - dateFrom.getMonth() + 12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  );
};

/**
 * Lightweight non-cryptographic hash function
 * @param text
 */
export const hashStr = (text: string): number => {
  let hash = 0;
  for (let i = 0; i < text.length; i += 1) {
    hash = text.charCodeAt(i) + ((hash << 5) - hash);
  }
  return hash;
};

/** creates a background color based off of input text */
export const textToColor = (
  text: string,
  // saturation?: number,
  // lightness?: number
): string => {
  const hash = hashStr(text);
  const colors = [
    "#F44336",
    "#E91E63",
    "#9C27B0",
    "#673AB7",
    "#3F51B5",
    "#2196F3",
    "#00BCD4",
    "#009688",
    "#4CAF50",
    "#FF9800",
    "#FF5722",
    "#607D8B",
  ];
  return colors[Math.abs(hash) % (colors.length - 1)] as string;

  // var h = hash % 360;
  // return (
  //   "hsl(" + h + ", " + (saturation || 61) + "%, " + (lightness || 60) + "%)"
  // );
};

/**
 * Converts a snake_case word into a phrase
 * e.g.
 * the_quick_brown_fox
 * becomes
 * The Quick Brown Fox
 * @param str the string to convert
 */
export const snakeCaseToPhrase = (str: string): string => {
  return str
    .split("_")
    .filter((x) => !!x)
    .map((x) => x[0]?.toUpperCase() + x.substring(1))
    .join(" ");
};

/**
 * Returns the period for a given interval count and unit.
 * Ex: if interval_count === 3 && interval_unit === "month", then periodUnit will be "quarter".
 * @param interval_count The count of units within an interval
 * @param interval_unit Either "month" or "quarter"
 */
export const getPeriodUnitFromInterval = (
  interval_count: number,
  interval_unit: string,
): string => {
  return interval_count === 3 && interval_unit === "month" ? "quarter" : "month";
};

/**
 * checks whether a string is a UUID.
 * @param str the uuid to check
 */
export const isUUID = (str: string): boolean =>
  /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/.test(str);

/** Calculates a vendor's bid price in cents given their `rate_months_1` and `max_contract_term_months`
 * @param rate_months_1
 * @param max_contract_term_months
 */
export const calculateBidPrice = (
  rate_months_1?: number | null,
  max_contract_term_months?: number | null,
): number => {
  return 1 - ((rate_months_1 ?? 0) / (100 * 100)) * (max_contract_term_months ?? 12);
};

const optionalPrecision = (number: number, precision?: number) =>
  precision !== undefined ? round(number, precision) : number;

/** parse a formatted number string ($20.02) into a raw number */
export const onlyNumbers = (number: string | number, precision?: number): number => {
  // the regex matches all numbers including symbols e.g. -$1.01
  if (typeof number === "string") {
    const filteredNumber = number.match(/^[-]|([\d]*[.]{0,1}[\d]+)/g)?.join("");
    const realNumber = filteredNumber !== undefined ? Number(filteredNumber) : Number(number);
    return optionalPrecision(realNumber, precision);
  }
  return optionalPrecision(number, precision);
};
