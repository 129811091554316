import styled from "@emotion/styled";

export type ISpacerProps = { x?: string; y?: string };

/**
 * This is a preliminary Spacer component. We will probably revise the API soon.
 */
export const Spacer = styled.div<ISpacerProps>`
  margin-left: ${(props) => props.x};
  margin-bottom: ${(props) => props.y};
`;
