import { ICommand, ICommandMapping } from "./types";

// IKeyMap is our interface to use with Mousetrap to register key bindings.
type IKeyMap = {
  [key: string]: readonly ICommand[];
};

// makeKeyMap takes the currently active commands and massages them into a IKeyMap
// which can them be easily used with Mousetrap.
export const makeKeyMap = (mapping: ICommandMapping): IKeyMap => {
  return Object.values(mapping)
    .flatMap((commands) =>
      commands.flatMap((commandInfo) =>
        commandInfo.bindings.map((binding) => [binding, commandInfo.command] as [string, ICommand]),
      ),
    )
    .reduce(
      (keymap, [binding, command]) => ({
        ...keymap,
        [binding]: [...(keymap[binding] ?? []), command],
      }),
      {} as IKeyMap,
    );
};
