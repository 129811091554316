import { css, Interpolation, Theme } from "@emotion/react";

export const globalColors: Interpolation<Theme> = (theme) => css`
  body {
    background-color: ${theme.components.App.background};
    color: ${theme.components.App.text};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme.components.App.text};
  }

  hr {
    border-bottom: 1px solid ${theme.components.Divider.default};
  }

  *::-webkit-scrollbar-thumb {
    background-color: ${theme.components.Scrollbar.normal};
    &:hover {
      background-color: ${theme.components.Scrollbar.hover};
    }
  }

  /* WebKit only works on, well, webkit. For Firefox scrollbars, we need different rules */
  * {
    scrollbar-color: ${theme.components.Scrollbar.normal} transparent;
  }
`;
